'use client';
import { SettingsPageLayoutHeaderLinks } from '@generated/types';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import { useState } from 'react';
import colors from 'tailwindcss/colors';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../safe-link';

export const MenuLink = (
  props: SettingsPageLayoutHeaderLinks & { index: number },
) => {
  const { target, colorOverride, text, subMenuLinks, index } = props;

  const [hover, setHover] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  return (
    <div
      className={`menu-item text-text_color flex-shrink-0 text-center group ${index === 0 ? 'ml-auto' : 'mx-auto first:ml-0'}  last:mr-0`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <SafeLink
        href={target || '#'}
        style={colorOverride ? { color: colorOverride } : {}}
        tina={tinaField(props, 'text')}
      >
        <span className="relative inline-block">
          {text}
          <span
            className={`absolute inset-x-0 bottom-0 h-[2px] transition-all duration-300 bg-transparent`}
            style={{
              backgroundColor: hover
                ? colorOverride || colors.current
                : 'transparent',
            }}
          ></span>
        </span>
      </SafeLink>
      {subMenuLinks && subMenuLinks?.length > 0 && (
        <div
          className={`absolute transition-all ${hover ? 'opacity-100' : 'opacity-0'}`}
        >
          <SubMenuSingle
            data={subMenuLinks}
            {...{ visibleMenu, setVisibleMenu }}
          />
        </div>
      )}
    </div>
  );
};
