import {
  Maybe,
  PageBlocksTwoColImageTwoColTextEntries,
  SettingsPageLayoutHeaderLinksSubMenuLinks,
} from '@generated/types';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import { Dispatch, SetStateAction } from 'react';
import { SafeLink } from '../safe-link';

export default function SubMenuSingle(
  props: {
    data:
      | Maybe<PageBlocksTwoColImageTwoColTextEntries>[]
      | Maybe<SettingsPageLayoutHeaderLinksSubMenuLinks>[];
  } & {
    hideDots?: boolean;
    hideTopDots?: boolean;
    setVisibleMenu: Dispatch<SetStateAction<number | null>>;
    visibleMenu: number | null;
  },
) {
  const { visibleMenu, setVisibleMenu } = props;

  const toggleMenu = (index: number) => {
    setVisibleMenu(visibleMenu === index ? null : index); // Toggle the submenu visibility
  };

  const { data, hideDots, hideTopDots } = props;

  return (
    <div className="submenu-single w-full font-light text-left">
      {!hideDots && !hideTopDots && <div className="w-full">.</div>}
      {!hideDots && !hideTopDots && <div className="w-full">.</div>}

      {data?.map((item, index: number) => (
        <div
          key={`submenu-single#${index}`}
          className={`${hideDots ? 'mt-2' : ''}`}
        >
          {index > 0 && !hideDots && <div className="w-full">.</div>}
          <div
            className={`menu-item-outer w-full  flex justify-between items-center group ${item && 'type' in item && item.type === 'List Text' ? '' : 'cursor-pointer'}`}
            onClick={() => {
              if (item && 'type' in item && item.type === 'List Text') {
                return;
              }
              if (
                item &&
                'typeDropdown' in item &&
                item.typeDropdown === true
              ) {
                toggleMenu(index);
                return;
              }
              if (item && 'fieldLink' in item && item.fieldLink) {
                window.open(item.fieldLink, '_blank');
                return;
              }
            }}
          >
            {item && 'fieldText' in item && item?.fieldText && (
              <span>. {item.fieldText}</span>
            )}
            {item && 'text' in item && item?.text && item?.target && (
              <SafeLink href={item.target || '#'} className="group">
                .&nbsp;{item.text}
              </SafeLink>
            )}

            {item &&
              (('typeDropdown' in item && item.typeDropdown) ||
                ('fieldLink' in item && item.fieldLink)) &&
              item.type === 'Submenu Single' && (
                <SubmenuSingleIcon
                  width={15}
                  height={25}
                  className={`text-text_color transform transition-transform duration-300 group-hover:-translate-x-8 ${visibleMenu === index ? 'rotate-180' : ''}`}
                />
              )}
          </div>
          <div
            className={`sub-menu mt w-full ultra-light transition-all duration-300 ease-in-out mb-[50px] mt-6 ${visibleMenu === index ? 'block' : 'hidden'}`}
          >
            {item && 'fieldDropdownText' in item && item.fieldDropdownText}
          </div>
        </div>
      ))}
    </div>
  );
}
