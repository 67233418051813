import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getLangAndCleanSlug(v: string[], locales: readonly string[]) {
  const [lang, ...slug] = v;
  const langCode = locales?.find((locale) => locale === lang);
  const cleanedSlug = langCode ? slug : [lang, ...slug];
  return { slug: cleanedSlug, lang: langCode ? lang : 'en' };
}

export const rgbToHex = (rgb: string) => {

  const [r, g, b] = rgb.match(/\d+/g)!.map(Number);

  return '#'+((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
}
