import { Site } from '@apps/site';
import type {
  GlobalPartsFragment,
  SettingsPartsFragment,
} from '@generated/types';
import { Toaster } from '@libs/ui/shared/toaster';
import { Fragment, ReactNode } from 'react';
import { RecursiveOmit } from '../Blocks/AnimationText';
import Footer from './Footer';
import HeaderBottom from './Header-Bottom';
import HeaderTop from './Header-Top';

export const Layout = (
  props: { children?: ReactNode } & {
    global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
    settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  } & { pageId?: string; site: Site },
) => {
  return (
    <Fragment>
      <div className="h-full relative overflow-hidden">
        <HeaderTop {...props} />
        <HeaderBottom {...props} />
        <main className="w-full space-y-4 pt-0 pb-0 flex-1 mt-[80px] xl:mt-[60px] hd:mt-[80px] 2k:mt-[100px]">
          {props.children}
          <Toaster />
        </main>
        <footer className="w-full">
          <Footer {...props} />
        </footer>
      </div>
    </Fragment>
  );
};
