import React, { HTMLAttributes } from 'react';
import { BiPencil } from 'react-icons/bi';
import {
  CheckboxGroup,
  // Group,
  GroupListField,
  GroupProps,
  ImageField,
  Input,
  NumberInput,
  Toggle,
  useCMS,
  wrapFieldsWithMeta,
  wrapFieldWithError,
} from 'tinacms';
const Header = ({ onClick, children }: HTMLAttributes<Element>) => {
  return (
    <div className="pt-1 mb-5">
      <button
        onClick={onClick}
        className="group px-2 py-2 h-11 pl-4 bg-white shadow focus:shadow-outline focus:border-blue-500 w-full border border-gray-100 text-gray-600 hover:text-blue-400 focus:text-blue-500 rounded flex justify-between items-center gap-2"
      >
        <span className="text-left text-sm font-semibold  overflow-hidden text-ellipsis whitespace-nowrap flex-1">
          {children}
        </span>{' '}
        <BiPencil className="h-5 w-auto transition-opacity duration-150 ease-out opacity-80 group-hover:opacity-90" />
      </button>
    </div>
  );
};

export const StyledGroup = wrapFieldWithError(
  ({ tinaForm, field }: GroupProps) => {
    const cms = useCMS();
    const [isExpanded, setExpanded] = React.useState<boolean>(false);
    return (
      <>
        <Header
          onClick={() => {
            const state = tinaForm.finalForm.getState();
            if (state.invalid === true) {
              // @ts-ignore
              cms.alerts.error('Cannot navigate away from an invalid form.');
              return;
            }

            // setExpanded((p) => !p)
            cms.dispatch({
              type: 'forms:set-active-field-name',
              value: { formId: tinaForm.id, fieldName: field.name },
            });
          }}
        >
          {field.label || field.name}
        </Header>
      </>
    );
  },
);

export const CorrespondingComponent = (props: any) => {
  console.log(props);
  const { input } = props;
  return (props?.field as any)?.type === 'boolean' ? (
    <Toggle {...props}></Toggle>
  ) : (props?.field as any)?.type === 'number' ? (
    <div className="[&>div>label]:invisible [&>div>input]:-mt-7 bg-red">
      <NumberInput
        {...input}
        onChange={(v) => {
          console.log(v.target.value);
          props?.input?.onChange(
            v.target.value ? Number(v.target.value) : undefined,
          );
        }}
        value={props?.input?.value}
      />
    </div>
  ) : (props?.field as any)?.type === 'string' ? (
    <Input {...input}></Input>
  ) : (props?.field as any)?.type === 'object' &&
    !(props?.field as any).list ? (
    <StyledGroup {...props} className="bg-red-500"></StyledGroup>
  ) : (props?.field as any)?.type === 'object' && (props?.field as any).list ? (
    <div className="[&>div>span>div]:invisible [&>div>span]:-mt-7 bg-red">
      <GroupListField {...props} />
    </div>
  ) : (props?.field as any)?.type === 'image' ? (
    <div className="[&>div>label]:invisible [&>div]:-mt-7 bg-red">
      <ImageField {...props} />
    </div>
  ) : (
    <div className="text-muted-foreground text-xs">
      No corresponding component found
    </div>
  );
};

export const CategoryListComponent = wrapFieldsWithMeta((props) => {
  return <CheckboxGroup {...(props as any)} />;
}) as any;
