'use client';
import { Site } from '@apps/site';
import { GlobalPartsFragment, SettingsPartsFragment } from '@generated/types';
import { getArrowString } from '@libs/ui/Layout/Header-Bottom';
import { Logo } from '@libs/ui/Layout/Header-Top/Logo';
import { LogoCosmetics } from '@libs/ui/Layout/Header-Top/LogoCosmetics';
import { MenuLink } from '@libs/ui/utils/MenuLink';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import { Burger, Close } from '@libs/ui/utils/Templates/Shapes'; // Import Burger and Close icons
import { useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import { SafeLink } from '../../utils/safe-link';

export default function HeaderTop(props: {
  global: RecursiveOmit<GlobalPartsFragment, '__typename'>;
  settings: RecursiveOmit<SettingsPartsFragment, '__typename'>;
  pageId?: string;
  site: Site;
}) {
  // State for mobile nav visibility
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Function to toggle the mobile navigation
  const toggleMobileNav = () => {
    if (isNavVisible) {
      setIsNavVisible(false);
      document.body.classList.remove('overflow-hidden');
    } else {
      setIsNavVisible(true);
      document.body.classList.add('overflow-hidden');
    }
  };

  // Function to generate lines of dots
  const generateDots = () => {
    const dots = [];

    let topNav;
    let bottomNav;

    // Get the height of the top and bottom navigation
    if (isClient) {
      topNav = document.getElementById('mobile-top');
      bottomNav = document.getElementById('mobile-bottom');
    }

    if (isClient && topNav && bottomNav) {
      const topNavHeight = topNav.offsetHeight;
      const bottomNavHeight = bottomNav.offsetHeight;
      const remainingHeight =
        window.innerHeight - (topNavHeight + bottomNavHeight);

      const numberOfLines = Math.floor((remainingHeight - 70) / 40);
      for (let i = 0; i < numberOfLines; i++) {
        dots.push(
          <div key={i} className="flex justify-start pt-[20px] pb-[20px]">
            <span className="bg-text_color rounded-full w-1 h-1"></span>
          </div>,
        );
      }
    }

    return dots;
  };

  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px] pt-0 pb-0 w-screen bg-background`}
      ></div>
      <header
        id="header-top"
        className={`fixed top-0 left-0 right-0 z-50 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px] pt-0 pb-0 w-screen bg-transparent ${props.site === 'main-site' ? 'lg:mix-blend-difference' : ''}`}
      >
        {/* Mobile Nav */}
        <div
          id="mobileNav"
          className={`flex flex-col lg:hidden top-0 h-screen w-[50vw] pl-[20px] absolute bg-background transition-right duration-300 z-30 ${
            isNavVisible ? 'right-0' : 'right-[-50vw]'
          }`}
        >
          {/* Top Nav Items */}
          <nav
            id="mobile-top"
            className="flex flex-col gh items-start pt-[80px]"
          >
            {props?.settings?.pageLayout?.header?.links?.map((link, ind) => (
              <div key={`mobileMenuItem#${ind}`} className={`pb-[40px] `}>
                <SafeLink
                  href={link?.target || '#'}
                  className="menu-item flex-shrink-0 text-center group text-text_color small-text relative inline-block"
                  style={
                    link?.colorOverride ? { color: link?.colorOverride } : {}
                  }
                >
                  {link?.text}
                </SafeLink>
                {link?.subMenuLinks && link.subMenuLinks?.length > 0 && (
                  <SubMenuSingle
                    data={link.subMenuLinks}
                    hideDots
                    {...{ visibleMenu, setVisibleMenu }}
                  />
                )}
              </div>
            ))}
          </nav>

          {/* Dots */}
          <div className="flex-1 overflow-hidden pt-[30px] pb-40px]">
            <div className="p-0">{generateDots()}</div>
          </div>

          {/* Bottom Nav Items */}
          <nav
            id="mobile-bottom"
            className="flex flex-col items-start pt-[20px] pb-[20px]"
          >
            {props?.settings?.pageLayout?.bottomNavigationBar?.links?.map(
              (link, index) => (
                <SafeLink
                  key={`header-bottom-link-${index}`}
                  href={link?.target ?? '#'}
                  className="py-1"
                  tina={tinaField(link, 'text')}
                >
                  {getArrowString(link?.text)}
                </SafeLink>
              ),
            )}
          </nav>
        </div>

        <div className="container p-0 header-container">
          <div className="contentGrid grid w-full p-0 h-[80px] xl:h-[60px] hd:h-[80px] 2k:h-[100px]">
            <div className="col-span-4 lg:col-span-1 flex items-center justify-between">
              <div className="flex-shrink-0">
                <SafeLink href="/">
                  {props.site === 'main-site' ? <Logo /> : <LogoCosmetics />}
                </SafeLink>
              </div>
              {/* Burger Icon or Close Icon */}
              <div
                id="burgerIcon"
                className="flex lg:hidden h-full w-[50px] cursor-pointer z-40 relative" // Higher z-index for burger icon
                onClick={toggleMobileNav} // Toggle mobile nav on click
              >
                <div
                  className={`absolute inset-0 flex items-center justify-center transition-transform transition-opacity duration-500 transform ${
                    isNavVisible
                      ? 'opacity-0 scale-0 rotate-90'
                      : 'opacity-100 scale-100 rotate-0'
                  }`}
                >
                  <Burger />
                </div>
                <div
                  className={`absolute inset-0 flex items-center justify-center transition-transform transition-opacity duration-500 transform ${
                    isNavVisible
                      ? 'opacity-100 scale-100 rotate-0'
                      : 'opacity-0 scale-0 rotate-90'
                  }`}
                >
                  <Close />
                </div>
              </div>
            </div>

            <nav className="hidden lg:flex col-span-3 items-center">
              <div
                className="items-center justify-between font-light grid grid-cols-3 grid-flow-row w-full xl:gap-[56px] hd:gap-[80px]"
                data-tina-field={tinaField(props?.settings?.pageLayout?.header)}
              >
                <div className={`flex flex-row`}>
                  <MenuLink
                    {...(props?.settings?.pageLayout?.header?.links?.[0] ?? {})}
                    index={0}
                  />
                </div>
                <div className={`col-span-2 flex flex-row`}>
                  {props?.settings?.pageLayout?.header?.links?.map(
                    (link, idx) =>
                      idx > 0 && (
                        <MenuLink
                          key={`menuLink#${idx}`}
                          {...link}
                          index={idx}
                        />
                      ),
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
