'use client';

import { ReactNode, useEffect, useState, useTransition } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';

import { SettingsPartsFragment } from '@generated/types';
import { useFormState } from 'react-dom';
import { BiInfoSquare } from 'react-icons/bi';
import { tinaField } from 'tinacms/dist/react';
import { RecursiveOmit } from '../../Blocks/AnimationText';
import {
  ContactFormSubmit,
  getHubSpotForm,
} from '../../Blocks/ContactForm/actions';
import { State } from '../../Blocks/ContactForm/ContactFormBase';

export default function HubSpotSubscribeForm<T extends FieldValues>({
  settings,
}: {
  settings?: RecursiveOmit<SettingsPartsFragment, '__typename'>;
}) {
  const subscribeFormId = '5b70c7c7-b2e5-4c1d-94bb-eacc5c00a089';
  const subscribePortalId = '46399702';
  const [formData, setFormData] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const formData = await getHubSpotForm(subscribeFormId);
        setFormData(formData);
      } catch (error) {
        // console.error(error);
      }
    })();
  }, []);

  const [isSubmitting, startTransition] = useTransition();
  const ContactFormSubmitWithProps = ContactFormSubmit.bind(
    null,
    subscribePortalId,
    subscribeFormId,
  );

  const [message, formAction] = useFormState<State, FieldValues>(
    ContactFormSubmitWithProps,
    null,
  );

  useEffect(() => {
    if (message?.status === 'success') {
      console.log('form submitted successfully');
    }
  }, [message]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    startTransition(() => {
      formAction(data);
    });
  };

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isTouched, setIsTouched] = useState<Record<string, boolean>>({});

  const isSubmitted = message?.status === 'success';

  useEffect(() => {
    setFormSubmitted(isSubmitted || false);
  }, [isSubmitted]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    trigger,
  } = useForm<T>();

  const handleBlurValidation = async (name: string, onBlur?: () => void) => {
    await trigger(name as Path<T>); // Trigger validation for the specified input field
    if (onBlur) onBlur(); // Call the original onBlur handler if provided
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleChangeValidation = (name: string) => {
    setIsTouched((prev) => ({ ...prev, [name]: true }));
  };

  const onInvalidSubmit = async () => {
    // Mark all required fields as touched
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
      className="w-full mb-8"
    >
      <div className="flex flex-row justify-center items-center space-x-2 flex-1  w-full border-b">
        <div className="w-full">
          <Controller
            name={'email' as Path<T>}
            control={control}
            render={(renderProps) => {
              return (
                <input
                  type="text"
                  onChange={(e) => {
                    handleChangeValidation('email');
                    renderProps.field.onChange(e);
                  }}
                  onBlur={() =>
                    handleBlurValidation('email', renderProps.field.onBlur)
                  }
                  placeholder={
                    settings?.pageLayout?.footer?.subscribeText ||
                    'Enter your email to join our newsletter *'
                  }
                  data-tina-field={tinaField(
                    settings?.pageLayout?.footer,
                    'subscribeText',
                  )}
                  className="!bg-none !bg-transparent shadow-none  resize-none !rounded-none border-none focus:outline-none focus:ring-0 py-4 md:py-6 w-full"
                />
              );
            }}
          />

          <p className="text-red-500/50 text-xs translate-y-1 md:h-0 overflow-visible">
            {Object.values(errors).length > 0 &&
              (Object.values(errors)[0]?.message as ReactNode)}
          </p>
        </div>

        <div className="relative mt-0">
          <button disabled={isSubmitting} type="submit">
            <span className="-mt-[4px]">
              <ArrowRight width="w-[24px]" />
            </span>
          </button>
        </div>
      </div>
      {isFormSubmitted && (
        <p className="bg-primary/10 p-4 flex mb-4 mt-1 items-center">
          <BiInfoSquare className="mr-2 text-lg" />
          Thank you!
        </p>
      )}
    </form>
  );
}
