import { Maybe, Scalars } from '@generated/types';
import { FontSize, FontWeight, Shape } from '@libs/tina/blocks/utils';
import Circle from '@libs/ui/utils/Templates/Shapes/Circle';
import HalfsquareLeft from '@libs/ui/utils/Templates/Shapes/HalfsquareLeft';
import Square from '@libs/ui/utils/Templates/Shapes/Square';

export type HeadlineParams = {
  fontSize?: FontSize | null | string;
  fontWeight?: FontWeight | null | string;
};

export function getHeadlineTag({
  fontSize,
}: HeadlineParams): keyof JSX.IntrinsicElements {
  switch (fontSize) {
    case FontSize.H1:
      return 'h1';
    case FontSize.H2:
      return 'h2';
    case FontSize.H3:
      return 'h3';
    case FontSize.H4:
      return 'h4';
    case FontSize.Body:
      return 'p';
    case FontSize.Caption:
      return 'span';
    default:
      return 'p';
  }
}

export function getFontWeightClass(
  props: HeadlineParams | undefined | null,
): string {
  if (!props) return 'font-normal';
  const { fontWeight = FontWeight.Regular } = props;
  switch (fontWeight) {
    case FontWeight.UltraLight:
      return 'font-thin';
    case FontWeight.Light:
      return 'font-light';
    case FontWeight.Regular:
      return 'font-normal';
    case FontWeight.Medium:
      return 'font-medium';
    case FontWeight.Bold:
      return 'font-bold';
    default:
      return 'font-normal';
  }
}

export function getFontSizeClass({ fontSize }: HeadlineParams): string {
  switch (fontSize) {
    case FontSize.H1:
      return 'heading-1';
    case FontSize.H2:
      return 'heading-2';
    case FontSize.H3:
      return 'heading-3';
    case FontSize.H4:
      return 'heading-4';
    case FontSize.Body:
      return 'body-text';
    case FontSize.Caption:
      return 'caption-text';
    default:
      return 'body-text';
  }
}

export function getTagClass(tag: string): string {
  switch (tag) {
    case 'h1':
      return 'heading-1 relative regular noserif';
    case 'h2':
      return 'heading-2 relative regular noserif';
    case 'h3':
      return 'heading-3 relative regular noserif';
    case 'h4':
      return 'heading-4 relative regular noserif';
    case 'h5':
      return 'heading-4 relative regular noserif';
    case 'h6':
      return 'heading-4 relative regular noserif';
    case 'p':
      return 'body-text relative regular noserif';
    case 'blockquote':
      return 'body-text relative blockquote-text regular noserif';
    case 'ol':
      return 'body-text relative ordered-list regular noserif';
    case 'ul':
      return 'body-text relative unordered-list regular noserif';
    case 'li':
      return 'body-text relative list-item regular noserif';
    case 'a':
      return 'body-text relative anchor regular noserif';
    default:
      return 'body-text relative regular noserif';
  }
}

export function isFontSize(fontSize: string): fontSize is FontSize {
  return Object.values(FontSize).includes(fontSize as FontSize);
}

export function isFontWeight(fontWeight: string): fontWeight is FontWeight {
  return Object.values(FontWeight).includes(fontWeight as FontWeight);
}

export function getVariantHeightClass(
  variantHeight: Maybe<Scalars['String']['output']> | undefined,
): string {
  switch (variantHeight) {
    case 'Small':
      return 'h-[500px]';
    case 'Large':
      return 'h-[1080px]';
    default:
      return 'h-screen min-h-screen';
  }
}

export function getHeight(
  variantHeight: Maybe<Scalars['String']['output']> | undefined,
): string {
  switch (variantHeight) {
    case 'Small':
      return 'h-[500px]';
    case 'Large':
      return 'h-[1080px]';
    default:
      return 'h-screen min-h-screen';
  }
}

export function getAlignmentClassName(
  variant: Maybe<Scalars['String']['output']> | undefined,
): string {
  return variant === 'Text Right' ? 'text-right' : 'text-left';
}

export function getVariantAlignmentClass(
  variant: Maybe<Scalars['String']['output']> | undefined,
): string {
  switch (variant) {
    case 'Headline Left - Text Right':
      return 'flex-row';
    case 'Headline Right - Text Left':
      return 'flex-row-reverse';
    default:
      return 'flex-row';
  }
}

export function renderShape(
  shape: string,
  position: 'left' | 'middle' | 'right',
) {
  switch (shape) {
    case Shape.Square:
      return <Square key={position} />;
    case Shape.Circle:
      return <Circle key={position} />;
    case Shape.Left:
      return <HalfsquareLeft key={position} />;
    default:
      return null;
  }
}

export type HeadlineInputType = {
  fontSize?: Maybe<string> | undefined;
  fontWeight?: Maybe<string> | undefined;
};

export function transformToHeadlineParams<T extends HeadlineInputType>(
  headline: Maybe<T> | undefined,
): HeadlineParams {
  if (!headline) return { fontSize: null, fontWeight: null };

  const fontSize = isFontSize(headline.fontSize ?? '')
    ? (headline.fontSize as FontSize)
    : null;
  const fontWeight = isFontWeight(headline.fontWeight ?? '')
    ? (headline.fontWeight as FontWeight)
    : null;

  return { fontSize, fontWeight };
}

export function getHeadlineTagFromData<T>(
  data: Maybe<T> | undefined,
): keyof JSX.IntrinsicElements {
  const headlineParams = transformToHeadlineParams<any>(data);
  return getHeadlineTag(headlineParams);
}

export function getFontWeightClassFromData<T>(
  data: Maybe<T> | undefined,
): string {
  const headlineParams = transformToHeadlineParams<any>(data);
  return getFontWeightClass(headlineParams);
}
