import { ObjectField, TinaField } from '@tinacms/schema-tools';
import { StyledGroup } from '../utils/conditional-component';

export enum FontSize {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  Body = 'Body',
  Caption = 'Caption',
}
export enum FontWeight {
  UltraLight = 'UltraLight',
  Light = 'Light',
  Regular = 'Regular',
  Medium = 'Medium',
  Bold = 'Bold',
}

export enum ElementSize {
  Large = 'Large',
  Small = 'Small',
}

export enum VerticalPosition {
  Top = 'Top',
  Middle = 'Middle',
  Bottom = 'Bottom',
}

export enum Sides {
  Left = 'Left',
  Right = 'Right',
}

export const textFields: any = [
  {
    type: 'string',
    label: 'Body Text',
    name: 'text',
    ui: {
      component: 'textarea',
    },
  },
  {
    type: 'string',
    label: 'Font Size',
    name: 'fontSize',
    ui: {
      component: 'select',
    },
    options: Object.values(FontSize),
  },
  {
    type: 'string',
    label: 'Font Weight',
    name: 'fontWeight',
    ui: {
      component: 'select',
    },
    options: Object.values(FontWeight),
  },
] as ObjectField['fields'];

export const headlineTextFields = [
  { ...textFields[0], label: 'Headline Text' },
  textFields[1],
  textFields[2],
];

export const sublineTextFields = [
  { ...textFields[0], label: 'Subline Text' },
  textFields[1],
  textFields[2],
];

export const bodyTextFields = [
  { ...textFields[0], label: 'Body Text' },
  textFields[1],
  textFields[2],
];


export const bodyDefaultItem = {
  text: '',
  fontSize: FontSize.Body,
  fontWeight: FontWeight.Light,
};

export const headlineDefaultItem = {
  text: '',
  fontSize: FontSize.H4,
  fontWeight: FontWeight.Light,
};

export const entryBodyStyle: TinaField = {
  type: 'object',
  label: 'Body',
  name: 'body',
  ui: {
    defaultItem: {
      fontSize: FontSize.Body,
      fontWeight: FontWeight.Light,
    },
    itemProps: (item: any) => {
      return {
        label: item?.text || 'Body',
      };
    },
    component: StyledGroup as any,
  },
  fields: [textFields[1], textFields[2]],
};

export const entryHeadlineStyle: TinaField = {
  type: 'object',
  label: 'Headline',
  name: 'headline',
  ui: {
    defaultItem: {
      fontSize: FontSize.H2,
      fontWeight: FontWeight.Light,
    },
    itemProps: (item: any) => {
      return {
        label: item?.text || 'Headline',
      };
    },
    component: StyledGroup as any,
  },
  fields: [textFields[1], textFields[2]],
};

export const entryStyle: TinaField = {
  type: 'object',
  label: 'Entry Style',
  name: 'entryStyle',
  ui: {
    defaultItem: {
      headline: {
        fontSize: 'H2',
        fontWeight: 'Light',
      },
      body: {
        fontSize: 'Body',
        fontWeight: 'Light',
      },
    },
    component: StyledGroup as any,
  },
  fields: [entryHeadlineStyle, entryBodyStyle],
};

export const columnStyle = {
  ...entryStyle,
  label: 'Column Style',
  name: 'columnStyle',
};

export const defaultColumn = {
  colHeadline: '',
  colBody: '',
};

export const defaultColumnStyle = {
  headline: {
    fontSize: 'H4',
    fontWeight: 'Light',
  },
  body: {
    fontSize: 'Body',
    fontWeight: 'Light',
  },
};

export enum Shape {
  Square = 'Square',
  Circle = 'Circle',
  Left = 'Left',
  Right = 'Right',
  Up = 'Up',
  Down = 'Down',
  Empty = 'Empty',
}

export const shapeOptions = Object.values(Shape);

export const shapeVariantField: TinaField = {
  type: 'string',
  label: 'Shape Variant',
  name: 'shape',
  ui: {
    component: 'select',
  },
  options: shapeOptions,
};

export const shapeOptionsDefaultItem = {
  left: Shape.Circle,
  middle: Shape.Right,
  right: Shape.Square,
};

export const headlineStyleDefaultItem = {
  fontSize: FontSize.H4,
  fontWeight: FontWeight.Light,
};

export const bodyStyleDefaultItem = {
  fontSize: FontSize.Body,
  fontWeight: FontWeight.Light,
};

export const entryStyleDefaultItem = {
  headline: bodyStyleDefaultItem,
  body: bodyStyleDefaultItem,
};

export const shapeOptionsField: TinaField = {
  type: 'object',
  label: 'Shape Options',
  name: 'shapeOptions',
  ui: {
    defaultItem: shapeOptionsDefaultItem,
    component: StyledGroup as any,
  },
  fields: [
    {
      type: 'string',
      label: 'Shape Left',
      name: 'left',
      ui: {
        component: 'select',
      },
      options: shapeOptions,
    },
    {
      type: 'string',
      label: 'Shape Middle',
      name: 'middle',
      ui: {
        component: 'select',
      },
      options: shapeOptions,
    },
    {
      type: 'string',
      label: 'Shape Right',
      name: 'right',
      ui: {
        component: 'select',
      },
      options: shapeOptions,
    },
  ],
};
